
















import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MintViewModel } from '../../viewmodels/mint-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    'mint-countdown': () => import('@/modules/mint/components/process/mint-countdown.vue'),
  },
})
export default class extends Vue {
  @Inject({}) vm!: MintViewModel
  walletStore = walletStore
}
